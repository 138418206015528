import React from "react"
import { Container, Online } from "../components"
import Seo from "../components/seo"

const OnlinePage = () => {
  return (
    <Container>
      <Seo title="オンライン診療" />
      <Online />
    </Container>
  )
}

export default OnlinePage
